/** Styles for react-split. */

:root {
  --gutter-border-color: #2c2c2c;
}

.gutter {
  background-color: #303030;
}

.gutter:hover {
  background-color: #616161;
}

.gutter.gutter-horizontal {
  border-left: 1px solid var(--gutter-border-color);
  border-right: 1px solid var(--gutter-border-color);
  cursor: col-resize;
}
.gutter.gutter.gutter-horizontal:hover {
  border: none;
}

.gutter.gutter-vertical {
  border-top: 1px solid var(--gutter-border-color);
  border-bottom: 1px solid var(--gutter-border-color);
  cursor: row-resize;
}
.gutter.gutter-vertical:hover {
  border: none;
}
