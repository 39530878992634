html,
body,
#root {
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%;
  overflow: hidden;
}

*::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

*::-webkit-scrollbar-track {
  background: transparent;
}

*::-webkit-scrollbar-thumb {
  background-color: #757575;
  opacity: 0.2;
  border-radius: 20px;
}

*::-webkit-scrollbar-thumb:hover {
  background-color: #bdbdbd;
}
